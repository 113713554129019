import { startOfToday } from 'date-fns';
import { ICompanyState } from 'store/company/interfaces';
import moment from 'moment';

export const getBirthDate = (border: string) => {
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  if (border === 'min')
    return new Date(today.getFullYear() - 120, today.getMonth(), today.getUTCDate());
  else if (border === 'max')
    return new Date(today.getFullYear() - 10, today.getMonth(), today.getUTCDate());
  else return new Date();
};

export const getDateByKey = (key: string) => {
  let keyDate = new Date(key);
  if (isNaN(keyDate.getTime())) {
    keyDate = new Date(`${key.split('-')[1]}-${key.split('-')[0]}-01`);
  }
  return keyDate;
};

export const getMinStartDate = (company: ICompanyState | null, endDate?: Date | null | string) => {
  if (endDate) {
    const temDate = new Date(endDate);
    //I hardcoded this value because this code needs just for the profit company
    //and just for some special use case, we definitely need to choose the first day of the month
    return new Date(temDate.getFullYear(), temDate.getMonth(), 1);
  }
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  if (company === null) return today;

  let minimumDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  /*  -------------------------------------------------------------------
      old logic to select any date after start date for flexible contract, 
      but in this comment https://hansefit.atlassian.net/browse/FE-1181?focusedCommentId=57330
      we have explanation that it shouldn't be possible for real customers 
      if (company.regularStartDate === 'flexible') {
        minimumDate = new Date(today.setDate(today.getDate() + 1));
      }
      ------------------------------------------------------------------- */
  let startDate = today;
  if (Object.keys(company.fees).length > 0) {
    startDate = getDateByKey(Object.keys(company.fees)[0]);
  }
  if (minimumDate < startDate) {
    minimumDate = startDate;
  }

  return minimumDate;
};
export const getMaxStartDate = (company: ICompanyState | null) => {
  const today = startOfToday();
  if (company === null) return today;

  const monthLimit = 6;
  const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const nextSixMonth = moment(firstDate).add(monthLimit, 'M').toDate();
  const feesKeys = Object.keys(company.fees);
  if (feesKeys.length > 0) {
    const date = new Date(feesKeys[feesKeys.length - 1]);
    return date < nextSixMonth ? date : nextSixMonth;
  } else {
    return nextSixMonth;
  }
};
